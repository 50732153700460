import MDBox from 'components/MDBox'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import Typography from '@mui/material/Typography'
import localforage from 'localforage'
import React from 'react'
import MDButton from 'components/MDButton'
import Singleselect from './inputs/Singleselect'
import Multiselect from './inputs/Multiselect'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import env from 'config'
import { useAppServices } from 'hook/services'
const AppSetup = () => {
  const AppService = useAppServices()
  const [workflows_data, setworkflows_data] = React.useState([])
  const [stripe_data, setstripe_data] = React.useState([])
  const [app_setup, setapp_setup] = React.useState({})
  const [customFields_data, setcustomFields_data] = React.useState([])
  const [saved_data, setsaved_data] = React.useState('')
  const [processing, setProcessing] = React.useState(false)
  const [errormsg, seterrormsg] = React.useState('')
  const [response, setresponse] = React.useState('')
  const [user_id, setuser_id] = React.useState('')
  const [defeult_password, setdefeult_password] = React.useState('')
  const [stripe, setstripe] = React.useState('')
  const [workflows, setworkflows] = React.useState('')
  const [premium_workflows, setpremium_workflows] = React.useState('')
  const [forgot_password, setforgot_password] = React.useState('')
  const [handlerefresh, sethandlerefresh] = React.useState(false)

  const BASE_URL = `${env.API_URL}/v1`

  var axios = require('axios')
  React.useEffect(async () => {
    const localBrand = await localforage.getItem('user')
    setuser_id(localBrand._id)

    axios
      .get(BASE_URL + '/superadmin/settings/workflows')
      .then((response) => {
        console.log(response.data, 'app_setupapp_setup')
        var sripe = response.data.data.products.data
        console.log(sripe, 'sripe')
        var prices = []
        for (let index = 0; index < sripe.length; index++) {
          const element = sripe[index]
          var temp = {
            name:
              element?.product +
              '-($' +
              element?.unit_amount / 100 +
              '/' +
              element?.recurring?.interval +
              ')',
            id: element.id,
          }
          console.log(temp, 'temp')

          prices.push(temp)
        }
        setstripe_data(prices)
        console.log(response.data.data.workflows.workflows, 'workflowsworkflowsworkflows')
        console.log(response.data.data.customFields.customFields, 'customFieldscustomFieldscustomFields')
        setworkflows_data(response.data.data.workflows.workflows)
        setcustomFields_data(response.data.data.customFields.customFields)
        if (response.data.data.workflows.status == 401) {
          seterrormsg('please check your Api key')
          setTimeout(() => {
            seterrormsg('')
          }, 5000)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
    axios
      .get(BASE_URL + '/superadmin/settings')
      .then(async (response) => {
        setsaved_data(response.data)
        setstripe(response.data.data.stripe)
        //   alert(response.data.data.stripe)
        setapp_setup(response.data.data)
        setworkflows(response.data.data.workflows)
        setpremium_workflows(response.data.data.workflows_p)
        setdefeult_password(response.data.data.defeult_password)
        setforgot_password(response.data.data.forgot_password)
        console.log(response.data.data, 'saved_data')
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  var data = ''
  const handleSubmit = (e) => {
    e.preventDefault()
    setProcessing(true)

    data = {
      stripe: e.target.stripe.value,
      workflows: e.target.workflows.value,
      workflows_p: e.target.premium_workflows.value,
      defeult_password: e.target.defeult_password.value,
      forgot_password: e.target.forgot_password.value,
      // agency_id: e.target.agency_id.value,
      // userID: e.target.userID.value,
      stripe_customer: e.target.stripe_customer.value,
      subscription: e.target.subscription.value,
      stripe_plan: e.target.stripe_plan.value,
      stripe_total_amount_spent: e.target.stripe_total_amount_spent.value,
      new_agent_login_info: e.target.new_agent_login_info.value,
      stripe_integration: e.target.stripe_integration.value,
      // new_topic_posted: e.target.new_topic_posted.value,
      // replied_topic_notification: e.target.replied_topic_notification.value,
      // new_ticket_submitted: e.target.new_ticket_submitted.value,
      // changing_password: e.target.changing_password.value,
      // user_id: user_id,
      // topic_email_custom_field: e.target?.topic_email_custom_field?.value,
      // topic_username_custom_field: e.target?.topic_username_custom_field?.value,
      // topic_message_custom_field: e.target?.topic_message_custom_field?.value,
      // topic_category_custom_field: e.target?.topic_category_custom_field?.value,
      // topic_name_custom_field: e.target?.topic_name_custom_field?.value,
      // ticket_link_custom_field: e.target?.ticket_link_custom_field?.value,
      // ticket_loom_video_custom_field: e.target?.ticket_loom_video_custom_field?.value,
      // ticket_description_custom_field: e.target?.ticket_description_custom_field?.value,
      // ticket_subject_custom_field: e.target?.ticket_subject_custom_field?.value,
      // ticket_location_id_custom_field: e.target?.ticket_location_id_custom_field?.value,
      // ticket_category_custom_field: e.target?.ticket_category_custom_field?.value,
      // ticket_type_custom_field: e.target?.ticket_type_custom_field?.value,
      // ticket_username_custom_field: e.target?.ticket_username_custom_field?.value,
      // ticket_id_custom_field: e.target?.ticket_id_custom_field?.value,
      // ticket_submit_workflow: e.target?.ticket_submit_workflow?.value,
      // assign_ticket_agent_workflow: e.target?.assign_ticket_agent_workflow?.value,
      // new_topic_posted: e.target?.new_topic_posted?.value,
      // replied_topic_notification: e.target?.replied_topic_notification?.value,
      // ticket_reply_workflow: e.target?.ticket_reply_workflow?.value,
    }
    console.log(data)

    // 4848 7150 5203 7927
    if (saved_data === '') {
      axios
        .post(BASE_URL + '/superadmin/settings/app_setup', { data })
        .then(async function (response) {
          console.log(response.data)
          setProcessing(false)
          setresponse(response.data.message)
          setTimeout(() => {
            setresponse('')
          }, 2000)
          setsaved_data(response.data)
          sethandlerefresh(!handlerefresh)
        })
        .catch(function (error) {
          setProcessing(false)
          seterrormsg(error.response.data.message)
          sethandlerefresh(!handlerefresh)
          setTimeout(() => {
            seterrormsg('')
          }, 2000)
          console.log(error.response.data)
        })
    } else {
      axios
        .post(BASE_URL + '/superadmin/settings/app_setup/update/' + saved_data.data._id, { data })
        .then(async function (response) {
          console.log(response.data)
          setProcessing(false)
          setresponse(response.data.message)
          sethandlerefresh(!handlerefresh)
          setTimeout(() => {
            setresponse('')
          }, 2000)
          setsaved_data(response.data)
        })
        .catch(function (error) {
          setProcessing(false)
          seterrormsg(error.response.data.message)
          sethandlerefresh(!handlerefresh)
          setTimeout(() => {
            seterrormsg('')
          }, 2000)
          console.log(error.response.data)
        })
    }
  }
  return (
    <MDBox pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            {/* <Grid container mt={2} mb={2} justifyContent="center">
              <Grid item xs={11}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h4" component="h2">
                    System
                  </MDTypography>
                </MDBox>
                <hr />
              </Grid>


              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Agency Account ID
                  </MDTypography>
                  <Singleselect
                    data={customFields_data}
                    edit_data={app_setup?.agency_id}
                    name="agency_id"
                  />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    User ID
                  </MDTypography>
                  <Singleselect
                    data={customFields_data}
                    edit_data={app_setup?.userID}
                    name="userID"
                  />
                </MDBox>
              </Grid>
            </Grid> */}

            {/* <Grid container mt={4} mb={2} justifyContent="center">
              <Grid item xs={11}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h4" component="h2">
                    Tickets
                  </MDTypography>
                </MDBox>
                <hr />
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Ticket Reply
                  </MDTypography>
                  <Singleselect data={workflows_data} edit_data={app_setup?.userID} name="ticket_reply_workflow" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2">
                    Assign Ticket Agent Workflow
                  </MDTypography>
                  <MDTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    ml={1}
                    mb={2}
                    textTransform="capitalize"
                  >
                    This workflow will trigger when a ticket is assigned to some user, it will send the workflow to the agent that a new ticket is assigned to him.
                  </MDTypography>
                  <Singleselect data={workflows_data} edit_data={app_setup?.userID} name="assign_ticket_agent_workflow" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2">
                    Ticket Submit Workflow
                  </MDTypography>
                  <MDTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    ml={1}
                    mb={2}
                    textTransform="capitalize"
                  >
                    This workflow will trigger when someone posted ticket, it will send the workflow to the agency that a new ticket is submitted.
                  </MDTypography>
                  <Singleselect data={workflows_data} edit_data={app_setup?.userID} name="ticket_submit_workflow" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for ticket_id when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.userID} name="ticket_id_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for username when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.ticket_username_custom_field} name="ticket_username_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for type when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.ticket_type_custom_field} name="ticket_type_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for category when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.ticket_category_custom_field} name="ticket_category_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for location Id when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.ticket_location_id_custom_field} name="ticket_location_id_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for subject when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.ticket_subject_custom_field} name="ticket_subject_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for description when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.ticket_description_custom_field} name="ticket_description_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for loom video when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.ticket_loom_video_custom_field} name="ticket_loom_video_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for ticket link when someone submit ticket.
                  </MDTypography>
                  <Singleselect data={customFields_data} edit_data={app_setup?.ticket_link_custom_field} name="ticket_link_custom_field" />
                </MDBox>
              </Grid>
            </Grid> */}

            {/* <Grid container mt={4} mb={2} justifyContent="center">
              <Grid item xs={11}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h4" component="h2">
                    Forums
                  </MDTypography>
                </MDBox>
                <hr />
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2">
                    Team Notified when new topic is posted.
                  </MDTypography>

                  <MDTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    ml={1}
                    mb={1}
                    textTransform="capitalize"
                  >
                    This workflow will trigger when a contact posted a topic in the forum, it will send the workflow to the agency a forum topic is posted.
                  </MDTypography>
                  <Singleselect
                    data={workflows_data}
                    edit_data={app_setup?.new_topic_posted}
                    name="new_topic_posted"
                  />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2">
                    Get notification when some replied to topic
                  </MDTypography>
                  <MDTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    ml={1}
                    mb={1}
                    textTransform="capitalize"
                  >
                    This workflow will trigger when a contact replies to a topic posted in the forum by agency, it will send the workflow to the agency who posted the forum topic.
                  </MDTypography>
                  <Singleselect
                    data={workflows_data}
                    edit_data={app_setup?.replied_topic_notification}
                    name="replied_topic_notification"
                  />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for topic name when someone create forum topic.
                  </MDTypography>
                  <Multiselect data={customFields_data} edit_data={app_setup?.topic_name_custom_field} name="topic_name_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for category when someone create forum topic.
                  </MDTypography>
                  <Multiselect data={customFields_data} edit_data={app_setup?.topic_category_custom_field} name="topic_category_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for message when someone create forum topic.
                  </MDTypography>
                  <Multiselect data={customFields_data} edit_data={app_setup?.topic_message_custom_field} name="topic_message_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for username when someone create forum topic.
                  </MDTypography>
                  <Multiselect data={customFields_data} edit_data={app_setup?.topic_username_custom_field} name="topic_username_custom_field" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Which custom field you want to store for user email when someone create forum topic.
                  </MDTypography>
                  <Multiselect data={customFields_data} edit_data={app_setup?.topic_email_custom_field} name="topic_email_custom_field" />
                </MDBox>
              </Grid>
            </Grid> */}

            <Grid container mt={4} mb={2} justifyContent="center">
              <Grid item xs={11}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h4" component="h2">
                    Payments
                  </MDTypography>
                </MDBox>
                <hr />
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2">
                    New user created and send access for payment
                  </MDTypography>
                  <MDTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    ml={1}
                    mb={1}
                    textTransform="capitalize"
                  >
                    This workflow will trigger when an agency purchased the subscription, it will send the access to the agency who purchased the subscription.
                  </MDTypography>
                  <Singleselect
                    data={workflows_data}
                    edit_data={premium_workflows}
                    name="premium_workflows"
                  />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox pt={2} px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Price IDs that trigger an account
                  </MDTypography>
                  <Multiselect data={stripe_data} edit_data={stripe} name="stripe" />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Stripe Customer ID
                  </MDTypography>
                  <Singleselect
                    data={customFields_data}
                    edit_data={app_setup?.stripe_customer}
                    name="stripe_customer"
                  />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Subscription ID
                  </MDTypography>
                  <Singleselect
                    data={customFields_data}
                    edit_data={app_setup?.subscription}
                    name="subscription"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Plan Name
                  </MDTypography>
                  <Singleselect
                    data={customFields_data}
                    edit_data={app_setup?.stripe_plan}
                    name="stripe_plan"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Total Amount Spent
                  </MDTypography>
                  <Singleselect
                    data={customFields_data}
                    edit_data={app_setup?.stripe_total_amount_spent}
                    name="stripe_total_amount_spent"
                  />
                </MDBox>
              </Grid>
            </Grid>

            <Grid container mt={4} mb={2} justifyContent="center">
              <Grid item xs={11}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h4" component="h2">
                    Passwords
                  </MDTypography>
                </MDBox>
                <hr />
              </Grid>

              <Grid item xs={11} mb={3}>

                <Grid item xs={11} mb={3}>
                  <MDBox px={3}>
                    <MDTypography id="modal-modal-title" variant="h6" component="h2">
                      New Agent/User Login Information
                    </MDTypography>

                    <MDTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      ml={1}
                      mb={2}
                      textTransform="capitalize"
                    >
                      This workflow will trigger when a new agent/User, it will send the workflow to the agent to send login access.
                    </MDTypography>
                    <Singleselect
                      data={workflows_data}
                      edit_data={app_setup?.new_agent_login_info}
                      name="new_agent_login_info"
                    />
                  </MDBox>
                </Grid>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2">
                    Forgot Password Workflows
                  </MDTypography>
                  <MDTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    ml={1}
                    mb={1}
                    textTransform="capitalize"
                  >
                    This workflow will trigger when an agency user sends a forgot password request, it will send the access to the registered email of the user.
                  </MDTypography>
                  <Singleselect
                    data={workflows_data}
                    edit_data={forgot_password}
                    name="forgot_password"
                  />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Default Password
                  </MDTypography>
                  <Singleselect
                    data={customFields_data}
                    edit_data={defeult_password}
                    name="defeult_password"
                  />
                </MDBox>
              </Grid>

              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2">
                    User update password workflow
                  </MDTypography>

                  <MDTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    ml={1}
                    mb={1}
                    textTransform="capitalize"
                  >
                    This workflow will trigger when an agency user updated his password, it will send the notification WorkFlow to the registered email of the user.
                  </MDTypography>
                  <Singleselect data={workflows_data} edit_data={workflows} name="workflows" />
                </MDBox>
              </Grid>
              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2">
                    Which workflow you want to run for the Stripe Integration after the GHL Integration
                  </MDTypography>
                  <Singleselect data={workflows_data} edit_data={app_setup?.stripe_integration} name="stripe_integration" />
                </MDBox>
              </Grid>
              {/* <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Changing Password
                  </MDTypography>
                  <Singleselect
                    data={workflows_data}
                    edit_data={app_setup?.changing_password}
                    name="changing_password"
                  />
                </MDBox>
              </Grid> */}
            </Grid>

            {/* <Grid container mt={5} mb={2} justifyContent="center">
              {errormsg != '' ? (
                <Stack sx={{ width: '90%' }} spacing={2}>
                  <Alert severity="error">{errormsg}</Alert>
                </Stack>
              ) : (
                <></>
              )}
              {response != '' ? (
                <Stack sx={{ width: '90%' }} spacing={2}>
                  <Alert severity="success">{response}</Alert>
                </Stack>
              ) : (
                <></>
              )}
            </Grid>
            <Grid container mt={2} mb={2} justifyContent="center">
            </Grid> */}

            {/* ew */}
            {/* <Grid container mt={2} mb={2} justifyContent="center">
              <Grid item xs={11} mb={3}>
                <MDBox px={3}>
                  <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                    Get notification when new ticket submitted
                  </MDTypography>
                  <Singleselect
                    data={workflows_data}
                    edit_data={app_setup?.new_ticket_submitted}
                    name="new_ticket_submitted"
                  />
                </MDBox>
              </Grid>
            </Grid> */}
            <Grid container mt={3} mb={2} justifyContent="center">
              <Grid item xs={11} mb={3}>
                <MDBox px={3} className="d-flex justify-content-end">
                  {saved_data === '' ? (
                    <>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        loading={processing}
                        disabled={processing}
                      >
                        Save
                      </MDButton>
                    </>
                  ) : (
                    <>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        loading={processing}
                        disabled={processing}
                      >
                        Update
                      </MDButton>
                    </>
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default AppSetup
