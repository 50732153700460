import { Environment } from '../enums'

const env = {
  API_URL: 'https://csmapi.gocsm.io/api',
  // API_URL: 'http://localhost:8082/api',
  // 
  GHL: {
    // CLIENT_ID: '64f8604282165264339a0ccc-lm7nf7n0',
    // CLIENT_SECRET: '84249575-8af0-44bf-adb0-d8cf81d6cecb',


    CLIENT_ID: '660ea4779dd464536dd04ee5-lul9263h',
    CLIENT_SECRET: '9f21e7ca-9c93-42aa-9368-31ae6e18abc6',
    Location_CLIENT_ID: '660ea48a37d30e10c50cf5dc-luld2rjx',
    Location_CLIENT_SECRET: 'd3051f6d-3c99-46d7-b669-1efe77b9c07e',
    Location_SCOPE:
      'contacts.readonly contacts.write campaigns.readonly conversations.readonly conversations.write forms.readonly conversations/message.write links.readonly links.write conversations/message.readonly locations.readonly locations/customFields.readonly locations/customFields.write locations/customValues.write locations/customValues.readonly locations/tags.write locations/tags.readonly locations/tasks.write locations/tasks.readonly opportunities.readonly opportunities.write locations/templates.readonly surveys.readonly users.write users.readonly workflows.readonly',
    SCOPE:
      'calendars.write businesses.readonly businesses.write oauth.readonly oauth.write saas/location.write saas/location.read saas/company.write saas/company.read contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly calendars.readonly calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write calendars/events.readonly calendars/events.write',
    REDIRECT: {
      ASSOCIATE: 'https://csmsuper.fixmyonline.com/integrations/associate',
      AGENCY: 'https://csmsuper.fixmyonline.com/integrations/associate/agency',
    },
  },
}
if (process.env.REACT_APP_ENV === Environment.DEVELOPMENT) {
  env.API_URL = 'https://csmapi.gocsm.io/api'
}
if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = 'https://csmapi.gocsm.io/api'
}
export default env
