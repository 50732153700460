import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useAgencyInfo } from "context/agency";
import Multiselect from './components/Multiselect'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function CreateProduct({ open, onClose, product, prices }) {
  const [agency, Update] = useAgencyInfo();
  const uploadImage = useUploadImage()
  // console.log(category)
  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices()
  const handleSubmit = async (e) => {
    setProcessing(true)
    e.preventDefault()

    const payload = {
      name: e.target.name.value,
      price: JSON.parse(e.target.price.value),
      // limit: e.target.limit.value,
      type: 'super-admin',
      agency_id: agency._id
    }
    console.log(payload)
    if (product) {
      payload._id = product._id
    }

    const { response } = await AppService.products[
      product?._id ? 'updateProduct' : 'createProduct'
    ]({
      payload,
      toaster: true,
    })
    setProcessing(false)
    if (response) {
      onClose()
    }
  }

  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        height={400}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Product</MDTypography>

          <MDBox my={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              defaultValue={product?.name}
              fullWidth
            />
          </MDBox>
          {/* <MDBox my={2}>
            <MDInput
              type="number"
              label="Account Limit"
              name="limit"
              defaultValue={product?.limit}
              fullWidth
            />
          </MDBox> */}
          <MDBox sx={{ fontSize: '15px' }}>
            {/* <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
              Stripe Price Id
            </MDTypography> */}
            <Multiselect data={prices} edit_data={product?.price || {}} isMulti={false} name="price" />
          </MDBox>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing}
            sx={{ mt: 4, mb: 1 }}
            fullWidth
          >
            {product?.name ? 'Update' : 'Save'}
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default CreateProduct
