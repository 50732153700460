/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import EditModal from '../../EditModal'
import { useAgencyInfo } from 'context/agency'
import { Roles } from 'enums/user'

export default function DataTableData() {
  const AppService = useAppServices()
  const [List, setList] = useState([])
  const [loader, setloader] = useState(true)
  const [agency] = useAgencyInfo()

  const getData = async () => {
    const { response } = await AppService.agency.filter()
    console.log(response, 'request_app')
    if (response) {
      setList(response.data)
    } else {
      setList([])
    }
    setloader(false)
  }
  const onLoad = () => {
    getData()
  }
  const handleDelete = async (id) => {
    console.log('ddddd', id)
    const { response } = await AppService.auth.DeleteTeam({
      query: `_id=${id}`,
    })
    if (response) onLoad()
  }

  useEffect(onLoad, [])
  return {
    loader: loader,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'location Id', accessor: 'location_id' },
        { Header: 'Subscription Id', accessor: 'subscription_id' },
        { Header: 'Package Id', accessor: 'package_id' },
        // { Header: 'Action', accessor: 'action' },
      ],

      rows: List.map((data) => ({
        location_id: data?.ghl?.location_id,
        subscription_id: <>
          <a style={{ color: 'inherit' }} href={`https://dashboard.stripe.com/subscriptions/${data?.subscription}`} target='_blank'>{data?.subscription}</a>
        </>,
        package_id: data?.package_id,
        // action: (
        //   <MDBox>
        //     <EditModal data={data} handleRefresh={onLoad} />
        //     <MDButton
        //       variant="contained"
        //       color="error"
        //       size="small"
        //       sx={{ marginLeft: 2 }}
        //       onClick={() => handleDelete(data._id)}
        //     >
        //       Delete
        //     </MDButton>
        //   </MDBox>
        // ),
      })),
    },
  }
}
